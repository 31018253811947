@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    border-radius: 50%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
