.container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 800;
}
