@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;

    a {
        outline: 0;
        border: 0;
    }

    @media (max-width: 768px) {
        padding: 0;
    }
}

.pages {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page {
    min-width: 1.2rem;
    height: 1.2rem;
    background-color: color("white");
    border-radius: 50%;
    cursor: pointer;
    color: color("lightblack");
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    word-break: normal;

    &:last-child {
        margin-right: 0;
    }
}

.currentPage {
    cursor: default;
    @include fontWeight('bold');
}

.direction {
    cursor: pointer;
    @include fontWeight('bold');
    font-size: 0.875rem;
    color: color("black");
    padding: 0 10px;
}

.directionDisabled {
    cursor: default;
    color: color("gray");
}
