@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.table {
  background-color: color("white");
  width: 100%;
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.searchContainer {
  padding: 0 0 15px 0;
  .search {
    display: flex;
    flex: 1;
    width: auto;
  }
}

.tableHeader {
  padding: 15px 0;
  display: flex;
  border-bottom: 1px solid color("gallery");
}

.tableHeaderTitle {
  flex: 1;
  font-weight: bold;
}

.container {
  display: flex;  
  flex-direction: column;

  .expressionContainer{
    flex:1;
  }
  .configContainer {
    display: flex;
    flex:1;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 50vh;

    .rulesContainer {
      flex: 3;
      padding: 1rem;
      margin: 15px;
      width: 100%;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .ruleContainer {
        display: flex;
        border-bottom: 1px solid color("gallery");
        align-items: center;
        .ruleInfo {
          padding: 6px 0;
        }
      }
    }
    .usersContainer {
      //  display: flex;
      flex: 5;
      width: 100%;
      padding: 1rem;
      margin: 15px;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .userContainer {
        display: flex;
        border-bottom: 1px solid color("gallery");
        align-items: center;
        .userInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
          .name {
            flex-grow: 1;
          }
          .email {
            font-size: 80%;
          }
        }
        .selected {
          padding-top: 6px;
        }
      }
    }

    .rolesContainer {
      flex: 3;
      width: 100%;
      padding: 1rem;
      margin: 15px;
      border: 1px solid color("gallery");
      border-radius: 10px;
      .roleContainer {
        display: flex;
        border-bottom: 1px solid color("gallery");
        align-items: center;
        .roleInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .checkbox {
          padding-top: 6px;
        }
      }
    }
    .operationsContainer {
      min-width: 54px;
      display: flex;
      justify-content: center;
      // padding: 1rem;
      margin: 15px;
      .multiButton {
        display: flex;
        flex-direction: column;
        button {
          margin: 0;
          flex: 1;
        }
        button:first-child {
          border-radius: 8px 8px 0 0;
        }
        button:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
    }
    .rowHover:hover {
      cursor: pointer;
      transition: 0.2s;

      .bodyColumn {
        background-color: color("alabaster");
      }
    }
    .striped {
      background-color: color("gallery");
    }

    .columnHeader {
      padding: 0.5rem 1rem;
      color: color("alto");
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      @include fontWeight("normal");
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 20px;
}

@include respond-below(lg) {
  .configContainer {
    flex-direction: column;
    .rulesContainer,
    .rolesContainer,
    .usersContainer,
    .operationsContainer {
      margin: 15px 0;
    }
    .operationsContainer {
      width: 100%;
    }
  }
}
