@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.formItem {
    display: block;
    margin-bottom: 20px;
}

.flexProperties {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}