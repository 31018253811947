@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.container {
    .rowSpace {
        margin-bottom: 1rem;

        .selectContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
        }
    }

    .label {
        text-align: left;
        font: normal normal bold 14px/17px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 0.8rem;
    }
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.permissionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fields {
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    @include elevation(5);
    position: relative;

    .removeButton {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
        display: none;

        width: 1.5rem;
        height: 1.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @include elevation(1, #ff0000);

        .icon {
            width: 1rem;
            height: 1rem;
        }
    }

    &:hover {
        .removeButton {
            display: flex;
        }
    }
}

.marginTop {
    margin-top: 1rem;
}