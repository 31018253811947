@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.button {
    border: 0;
    color: color('white');
    border-radius: 25px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    margin-left: 1rem;
    &.disabled{
        opacity: 0.2;
        cursor: not-allowed;

        &.primary {
            box-shadow: -7px 8px 23px 0px color('primary');
        }
    }

    &.sizeNormal {
        padding: 0.7rem 1.8rem;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.5rem 1rem;
        font-size: 14px;
    }

    &.sizeExtraSmall {
        padding: 0.5rem 1rem;
        font-size: 10px;
    }
}

.buttonIcon {
    border: 0;
    color: color('white');
    border-radius: 50%;
    font-size: 13px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    
    &.sizeNormal {
        padding: 0.1rem;
        width: 43px;
        height: 43px;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.1rem;
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    &.sizeExtraSmall {
        padding: 0.1rem;
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
}

.primary {
    background-color: color('primaryDark');
    border: 1px solid color('primaryDark');

    &:hover {
        background-color: darken(color('primaryDark'), 5%);
    }
}

.primaryAlt {
    background-color: color('defaultGreen');
    border: 1px solid color('defaultGreen');

    &:hover {
        background-color: darken(color('defaultGreen'), 5%);
    }
}

.danger {
    background-color: color('danger');
    border: 1px solid color('danger');

    &:hover {
        background-color: darken(color('danger'), 5%);
    }
}

.success {
    background-color: color('primaryLight');
    border: 1px solid color('primaryLight');

    &:hover {
        background-color: darken(color('primaryLight'), 5%);
    }
}

.secondary {
    background-color: color('white');
    color: color('primaryDark');
    border: 2px solid color('primaryDark');

    &:hover {
        background-color: darken(color('white'), 5%);
    }
}

.popover {
    background-color: color('white');
    color: color('skyBlue');
    border: 1px solid color('skyBlue');

    &:hover {
        background-color: darken(color('white'), 5%);
    }
}

.secondaryDanger {
    background-color: color('white');
    color: color('danger');

    &:hover {
        background-color: darken(color('white'),  5%);
    }
}