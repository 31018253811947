@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.tabHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bodyColumn {
    padding: 0.5rem important;    
}