@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    background-color: color('white');
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1.3rem 1.1rem;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerTitle {
    color: color('black');
    @include fontWeight('bold');
    font-size: 1rem;
    flex: 1;
    margin: 0;
    padding: 0;
    min-height: 3rem;
    display: flex;
    align-items: center;
}

.headerOptions {
    flex: 1;
    align-items: center;
    padding-left: 0.5rem;  
}