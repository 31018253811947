@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.masterContainer {
    display: flex;
}
.container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    
    .containerTooltip {
        width: 100%;
        height: 100%;
    }
}

.legendContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .legend {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .legendCircle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    }
}
