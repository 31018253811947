@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    flex: 1;
    @include fontWeight('bold');
    font-size: 1.8rem;
    color: color('black');
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}