@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

$sidebarWidth: 16%;
$sidebarMaxWidth: 19rem;
$sidebarMinWidth: 240px;

.container {
    position: relative;
    display: flex;
    height: 100%;
}

.sidebarContainer {
    display: none;
}
 

.sidebarContainer.active {    
    display: flex;
     width: $sidebarWidth;
     max-width: $sidebarMaxWidth;
     min-width: $sidebarMinWidth;
}


.contentContainer {
    flex: 1;
    max-width: 100%;
    overflow: auto;
    @include scrollbars();
}
 

@media screen and (max-width: 600px) {
    .contentContainer.active {                    
        min-width: 100%;
    }
  }









.btnmenu {
    display: inline-block;
    padding: 7px 10px;
    background-color: transparent;
    cursor: pointer;
    margin: 10px;
    z-index: 3;
    position: fixed;
}

.bar {
    display: block;
    background-color: #0799d3;
    width: 30px;
    height: 3px;
    border-radius: 5px;
    margin: 5px auto;
    transition: background-color .5s ease-in, transform .5s ease-in, width .5s ease-in;
}


input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked~.content {
    transform: translateX(20%);
}

input[type="checkbox"]:checked~label .bar {
    background-color: #fff;
}

input[type="checkbox"]:checked~label .top {
    -webkit-transform: translateY(0px) rotateZ(45deg);
    -moz-transform: translateY(0px) rotateZ(45deg);
    -ms-transform: translateY(0px) rotateZ(45deg);
    -o-transform: translateY(0px) rotateZ(45deg);
    transform: translateY(0px) rotateZ(45deg);
}

input[type="checkbox"]:checked~label .bottom {
    -webkit-transform: translateY(-15px) rotateZ(-45deg);
    -moz-transform: translateY(-15px) rotateZ(-45deg);
    -ms-transform: translateY(-15px) rotateZ(-45deg);
    -o-transform: translateY(-15px) rotateZ(-45deg);
    transform: translateY(-15px) rotateZ(-45deg);
}

input[type="checkbox"]:checked~label .middle {
    width: 0;
}
