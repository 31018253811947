@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
}