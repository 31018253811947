@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.imagePreview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    @include elevation(4);
    overflow: hidden;
}

.editorContent {
    height: 100%;
    position: relative;
    cursor: crosshair;

    .editorSave{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.options {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;

    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        width: 25px;
        height: 25px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }

    .sizeContainer {
        display: flex;
        width: 100px;
        justify-content: center;
        align-items: center;
        
        .sizeContentXs {
            width: 25px;
            padding: 2px;
            height: 2px;
            background: black;
        }

        .sizeContentM {
            width: 25px;
            height: 4px;
            background: black;
        }

        .sizeContentL {
            width: 25px;
            height: 6px;
            background: black;
        }

        .sizeContentXl {
            width: 25px;
            height: 8px;
            background: black;
        }
    }
}
