@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.label {
    display: block;
    margin-bottom: 4px;
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: 0px;
    color: color('lightDark');
}

.requiredField{
    color: color('danger');
}