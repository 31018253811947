@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1rem;
        font-weight: bold;
        color: black;
    }
}

.requirementContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;
}

.fill {
    flex: 1;
}

.dateContainer {
    align-self: flex-end;
    font-size: 0.7rem;
    font-weight: bold;

    .date {
        margin-left: 0.5rem;
    }
}

.serverListContainer {
    display: flex;
    flex-direction: row;

    .serverListTitle {
        font-size: 0.7rem;    
    }

    .serverCell {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 0.5rem;

        .serverRepetitionContainer {
            position: relative;
            font-size: 0.7rem;
        
            .serverRepetition {
                background: color(primaryLight);
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(75%, -25%);
                border-radius: 50%;
                font-size: 0.5rem;
                width: 0.7rem;
                height: 0.7rem;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                @include elevation(2);
            }
        }
    }
}

.alertContainer {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.floatingBadge {
    align-items: center;
    display: flex;
    justify-content: center;
    color: color("white");
    border-radius: 50%;
    background-color: color("danger");
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0;
    right: 0;
    font-size: 0.6rem;
    line-height: 0;
    text-align: center;
}