@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container {
    display: flex;  
    flex-direction: column;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 20px;
  }